import React, { useEffect, useState, useRef } from "react";
import { RefreshComponet } from "../utility/UserDetails";
import {
    Tabs,
    Row,
    Input,
    Button,
    Tooltip,
    Card,
    Col,
    Checkbox,
    Modal,
    Empty,
    Select,
    Spin,
    Typography,
    Badge,
    Tag
} from "antd";
import {
    EyeFilled,
    DiffOutlined,
    ExclamationCircleFilled,
    CloseOutlined,
    SearchOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { message as messageApi } from 'antd';
import AxiosInstance from "../services/axiosInstance";
import * as moment from "moment";
import { Toaster } from "../utility/Toaster";
import InfiniteScroll from "react-infinite-scroll-component";
import SignatureCanvas from "react-signature-canvas";
import SignatureModal from './home/Cli-Emp-Signatures';
import { Divider, Skeleton } from "antd";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const { TabPane } = Tabs;
const { Meta } = Card;
const { TextArea } = Input;
const Option = Select.Option;
const { Title } = Typography;
const { confirm } = Modal;
const History = () => {
    const displayItems = JSON.parse(localStorage.getItem('History_Screen'));
    const { t } = useTranslation();
    const location = useLocation();
    const token = localStorage.getItem("rType");
    const [rType, setRType] = useState(token || '');
    const inputRef = useRef(null);
    let tabb = "";
    if(location.state !== null && location.state !== undefined && location?.state){
       if(location?.state?.missingSignData == "Client Missing Signature"){ 
        tabb = "3"
       }else if(location?.state?.missingSignData == "Missing Signature"){
        tabb = "2"
       }else tabb = "1";
    }else tabb = "1";
    console.log(tabb,"tabb")
    const [activeTab, setActiveTab] = useState(tabb);
    const [historyData, setHistoryData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [taskData, setTaskData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [note, setNote] = useState(""); // State to store the note value
    const [loading, setLoading] = useState(false);
    const [warningLoading, setWarningLoading] = useState(false);
    const [Id, setIdForClickOnIcon] = useState([]);
    const [showTask, setShowTask] = useState(false);
    const [checkClientArry, setcheckClientArry] = useState([])
    const [selectAllActive, setSelectAllActive] = useState(true);
    //Drop down States
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    //infinite scroll state
    const [hasMore, setHasMore] = useState(true);
    const [pageNo, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState("");

    //signature modal
    const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
    const [showClientSignature, setShowClientSignature] = useState(false);

    const clientSigCanvas = useRef();
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        fetchHistoryData(pageNo);
    }, [activeTab]);

    const fetchMoreData = () => {
        setPage(pageNo + 1); // Increment page number
    };

    useEffect(() => {
        if (pageNo > 1) {
            fetchHistoryData(pageNo); // Fetch items when page number changes
        }
    }, [pageNo]); // Fetch items when page number changes

    useEffect(() => {
        if (shouldFetchData) {
            setShouldFetchData(false);
            fetchHistoryData(1);
        }
    }, [shouldFetchData]);

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
        if (userInfo) {
            setUserType(userInfo.type);
        }
    }, []);

    const fetchHistoryData = async (pageNo) => {
        fetchClient();
        setLoading(true);
        try {
            let User_Type = JSON.parse(localStorage.getItem("USER_INFO"));
            let endPoint = { e: "emp/time-card", c: "client/timecard" };
            let local = User_Type.type ? endPoint[User_Type.type] : "";
            // const URL = "emp/time-card";
            const page = pageNo;
            const itemPerPage = 20;
            let params = { page, itemPerPage };
            if (location.state !== null && location.state !== undefined && location.state.missingSignData) {
                const missingSignatures = location.state.missingSignData;
                if (missingSignatures == 'Client Missing Signature') {
                    //  setActiveTab("3")
                    if (User_Type.type === "e") {
                        params.scheduleStatus = "Finished";
                    } else {
                        params.status = "Finished";
                    }
                    location.state = null
                }
                else {
                    if (User_Type.type === "e") {
                        setActiveTab("2")
                        params.scheduleStatus = "Ended";
                    } else {
                        params.status = "Finished";
                    }
                    location.state = null
                }
            }
            else {
                if (activeTab == "2") {
                    if (User_Type.type === "e") {
                        params.scheduleStatus = "Ended";
                    } else {
                        params.status = "Finished";
                    }
                    // params.scheduleStatus = "Ended";
                    if (selectedClient && selectedClient !== "all") {
                        params.clientId = selectedClient;
                    }
                } else if (activeTab == "3") {
                    if (User_Type.type === "e") {
                        params.scheduleStatus = "Finished";
                    } else {
                        params.status = "Finished";
                    }
                    if (selectedClient && selectedClient !== "all") {
                        params.clientId = selectedClient;
                    }
                }

            }





            const response = await AxiosInstance.get(local, {
                params: params,
            });
            if (response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
            const res = response.data.data;

            setLoading(false);
            if (res && res.rows && Array.isArray(res.rows)) {
                res.rows.forEach((x) => {
                    //x.duration = 'N/A';
                    if (x.aSTime && x.aETime) {
                        let start = moment.utc(x.aSTime, "HH:mm A");
                        let end = moment.utc(x.aETime, "HH:mm A");
                        let d = moment.duration(end.diff(start));
                        let time = d["_data"];
                        x.duration = time.hours + ":" + time.minutes;
                    } else {
                        if (x.sTime && x.eTime) {
                            let start = moment.utc(x.sTime, "HH:mm A");
                            let end = moment.utc(x.eTime, "HH:mm A");
                            let d = moment.duration(end.diff(start));
                            let time = d["_data"];
                            x.duration = time.hours + ":" + time.minutes;
                        }
                    }
                });

                setTotalItem(res.pagination.size);
                setLoading(false);
                setHistoryData((prevItems) => [...prevItems, ...res.rows]);
                if (res.rows.length === 0) {
                    setHasMore(false);
                }
                setSelectAllActive(true)
            } else {
                // // console.error("Unexpected data format:", res);
                setLoading(false);
                setHasMore(false); // Stop processing if data format is unexpected
            }
        } catch (error) {
            setLoading(false);
            // // console.error("Error fetching history data:", error);
        }
    };


    const getColor = (c) => {
        switch (c.scheduleStatus) {
            case "New":
                return "teal";
            case "Completed":
                return "rgb(50, 205, 50)";
            case "Ended":
                return "#808080";
            case "Cancelled":
                return "#FF0000";
            case "Inprogress":
                return "#F2AA4CFF";
            case "Finished":
                return "#13c2c2";
            case "Unverified":
                return "#000000";
            default:
                return "#1E90FF";
        }
    };

    const fetchClient = async () => {
        try {
            const URL = "/emp/my-client";
            const response = await AxiosInstance.get(URL);
            const res = response.data;
            if (res.success) {
                setClients(res.data);
            }
        } catch (error) {
            // // console.error("Error fetching history data:", error);
        }
    };

    const handleTabClick = (key) => {
        setActiveTab(key);
        resetState();
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                // Remove the ID if it's already selected
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                // Add the ID if it's not selected
                return [...prevSelectedIds, id];
            }
        });
    };
    // // console.log(historyData, 'historydaya')


    const allArrayAreEqual = () => {
        const result = checkClientArry.every(element => {
            if (element === checkClientArry[0]) {
                return true;
            }
        });
        return result;
    }
    const handleSelectAll = () => {
        const allIds = historyData.map((item) => item._id);
        setSelectedIds(allIds); // Update selectedIds with all IDs from data
        setSelectAllActive(false); // Hide "Select All" button
    };

    const handleDeselectAll = () => {
        setSelectedIds([]);
        setSelectAllActive(true); // Hide "Deselect All" button
    };

    const handleOk = () => {
        setLoading(true);

        let obj = { ids: [], note: note };
        if (selectedIds.length > 0) {
            obj.ids = selectedIds;
        } else {
            obj.ids = [Id];
        }

        if (!note) {
            Toaster("error", "Error", "Please fill the note field");
            setLoading(false);
            return;
        }
        const URL = "emp/attach-note-schedules";
        AxiosInstance.put(URL, obj)
            .then(({ data }) => {
                if (data.success) {
                    Toaster("success", "Success", data.message);
                    setSelectedIds([]);
                    setHistoryData([]);
                    setIsModalOpen(false);
                    setLoading(false);
                    setIdForClickOnIcon([]);
                    setShouldFetchData(true);
                } else {
                    // // console.log(data.message);
                }
            })
            .catch((err) => {
                // // console.log(err);
                Toaster("error", "Error", err.response.data.message);
                setLoading(false);
            });
    };

    const handleChange = (e) => {
        setNote(e.target.value); // Update the note value as the user types
    };

    const handleCopy = () => {
        const textarea = document.createElement("textarea");
        textarea.textContent = note;
        document.body.append(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        Toaster("success", "Copied", "Text copied successfully");
    };
    const showModal = () => {
        setIsModalOpen(true);
        setNote("");
    };
    const openSignatureModal = (selectedIds) => {
        const array = historyData.filter(item => selectedIds.includes(item._id)).map(item => item.clientId?._id)
        setcheckClientArry(array);
        if (activeTab === '2') {
            if (userType === "e") setShowEmployeeSignature(true);
            else setShowClientSignature(true);
        }
        else if (activeTab === '3') {
            showModalClientPin(selectedIds);
        }
    };

    const getEmpSignature = (URL) => {
        const eSign = URL
        employeeSignature(eSign);
    };
    const getClientSignature = (URL) => {
        const cSign = URL
        clientSignature(cSign);
    };

    const employeeSignature = async (employeeSig) => {
        try {
            setLoading(true);
            if (selectedIds.length === 0) {
                return;
            }
            const endpoint = { e: "emp/multiple-schedule", c: "client/multi-sign" };
            let User_Type = JSON.parse(localStorage.getItem("USER_INFO"));
            let local = User_Type.type ? endpoint[User_Type.type] : '';
            const signatureData = {
                ids: selectedIds,
                eSign: employeeSig,
            };

            AxiosInstance.post(local, signatureData)
                .then(({ data }) => {

                    if (data.success) {
                        if (User_Type.type === 'e') {
                            const duplicate = allArrayAreEqual();
                            if (!duplicate && (User_Type.type === "e" && !checkClientArry.length)) {
                                setHistoryData([])
                                this.toastCtrl.presentToast(this.translate.instant(data.message));
                                Toaster("success", data.message, "");
                            } else {
                                setShowEmployeeSignature(false)
                                ClientSignAlert(selectedIds[0], data.data.cSignService);
                            }
                        } else {
                            setHistoryData([])
                        }

                    } else {
                        Toaster("error", data.message, "");
                        // this.toastCtrl.errorToast("ATTENTION", data.message);// this.toastCtrl.presentToast(this.translate.instant(res.message));
                    }
                })
                .catch((err) => {
                    // // console.log(err);
                    Toaster("error", "Error", err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            // // console.error(error);
        }
    };

    const ClientSignAlert = (id, isService, dir = "") => {
        let schedule;
        if (dir) schedule = historyData.filter(x => x._id == id)[0];
        if (schedule) isService = schedule.cSignService || false;
        if (historyData.length !== 0) {
            let dup = allArrayAreEqual();
            if (dup) {
                if (isService) showModalClientPin(id);
                else setShowClientSignature(true);

            } else {
                Toaster("error", "Please select same client for signatures", "");
                // this.toastCtrl.presentToast("Please select same client for signatures");
                setHistoryData([])
                // this.checkClientArry = [];
                // historyData.map(x => {
                //   x.isChecked = false;
                // });
            }
        }
    }

    const clientSignature = (clientSig) => {
        // // console.log(selectedClient, "test test test")
        setWarningLoading(true);
        let local = 'emp/client-multi-sign';
        let signatureData = {
            ids: selectedIds,
            cSign: clientSig,
            clientId: checkClientArry[0]
        }
        if (rType == "rp") signatureData.rp = 'rp';
        AxiosInstance.post(local, signatureData)
            .then(({ data }) => {
                setWarningLoading(false);
                if (data.success) {
                    setShowClientSignature(false);
                    Toaster("success", "Success", data.message);
                    setSelectedIds([]);
                    setHistoryData([]);
                    setIsModalOpen(false);
                    setLoading(false);
                    setIdForClickOnIcon([]);
                    setShouldFetchData(true);
                } else {
                    Toaster("info", "ATTENTION", data.message);
                }
            })
            .catch((err) => {
                // // console.log(err);
                Toaster("error", "Error", err.message);
                setWarningLoading(false);
            });
    }




    const showModalClientPin = (dataId) => {
        confirm({
            title: t('Do you want client signature? Please enter client signature pin'),
            icon: <ExclamationCircleFilled />,
            content: (
                <Input
                    placeholder={t("Enter PIN")} maxLength={5}
                    ref={inputRef}
                />
            ),
            onOk: () => {
                setWarningLoading(true);
                const pinValue = inputRef.current?.input.value.trim();
                if (pinValue) {
                    let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
                    let User_Type = JSON.parse(localStorage.getItem("USER_INFO"));
                    let local = User_Type.type ? endPoint[User_Type.type] : '';
                    let obj = {
                        id: Array.isArray(dataId) ? dataId[0] : dataId,
                        // id: dataId,
                        pin: pinValue
                    };
                    AxiosInstance.post(local, obj)
                        .then(({ data }) => {
                            setWarningLoading(false);
                            if (data.success) {
                                messageApi.success(data.message);
                                setShowClientSignature(true);
                                // setShouldFetchData(true)
                            } else {
                                messageApi.error(data.message);
                                showModalClientPin(dataId); // Show modal again if there's an error
                            }
                        })
                        .catch((err) => {
                            // // console.error(err);
                            messageApi.error(err.message);
                            setWarningLoading(false);
                        });
                } else {
                    messageApi.error('Please enter a PIN');
                    setWarningLoading(false);
                    showModalClientPin(dataId);
                }

            },
            onCancel() {
                setSelectedIds([]);
                setHistoryData([]);
                setIsModalOpen(false);
                setLoading(false);
                setIdForClickOnIcon([]);
                setShouldFetchData(true);
            },
        });
    };

    const showNoteModal = (item) => {
        setIsModalOpen(true);
        setNote(item.notes[0]?.note);
        setIdForClickOnIcon(item._id);
    };
    const showTaskModal = (isCheck, item) => {
        setShowTask(isCheck);
        setTaskData(item);
    };
    const closeSignatureModal = () => {
        setSelectedIds([]);
        setHistoryData([]);
        setIsModalOpen(false);
        setLoading(false);
        setIdForClickOnIcon([]);
        setShouldFetchData(true);
        setShowEmployeeSignature(false);
        setShowClientSignature(false);
    }
    const handleCancel = () => {
        setShowTask(false);
        setIsModalOpen(false);
        // setShowEmployeeSignature(false);
        setNote("");
        clientSigCanvas?.current?.clear();
    };
    const filteredHistoryData = historyData.filter(
        (item) =>
            item.clientId.fName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.clientId.lName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.scheduleStatus.toLowerCase().includes(searchValue.toLowerCase())
        // item.scheduleStatus.toLowerCase().includes(searchValue.toLowerCase())
    );

    //in case of tabs change we reset all state null
    const resetState = () => {
        setHistoryData([]);
        setIsModalOpen(false);
        setShouldFetchData(false);
        setSearchValue("");
        setTaskData([]);
        setSelectedIds([]);
        setNote("");
        setLoading(false);
        setIdForClickOnIcon([]);
        setShowTask(false);
        setShowEmployeeSignature(false);
        setClients([]);
        setSelectedClient("");
        setPage(1);
        // setHasMore(true)
    };

    // drop down methods
    const onChange = (value) => {
        // if (value === "all") {
        //   // console.log(`All ${value}`);
        // } else {
        //   // console.log(`clientId: ${value}`);
        // }
        setHistoryData([]);
        setSelectedClient(value);
        setShouldFetchData(true);
    };
    //End drop methods
    return (
        <>
            <Spin
                spinning={warningLoading}
                size="large"
                fullscreen
                tip={t("FetchingYourData")}
            />
            <Tabs
                activeKey={activeTab}
                onChange={handleTabClick}
                left
                size="middle"
                type="card"
            >
                <TabPane
                    tab={
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {/* <span>
                <CheckCircleOutlined />
              </span> */}
                            <span>
                                {t("All")}
                            </span>
                        </div>
                    }
                    key="1"
                >
                    <Row
                        gutter={[8, 0]}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 5,
                            marginRight: 5,
                        }}
                    >
                        <Col
                            span={6}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={6}
                            xl={displayItems.historyLabelNote ? 12 : 24}
                        >
                            <Input
                                style={{ borderColor: '#91caff' }}
                                size="small"
                                placeholder={t("SearchByName")}
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                suffix={
                                    // Conditional rendering of the cross icon
                                    searchValue.length > 0 && (
                                        <Button
                                            type="link"
                                            onClick={() => setSearchValue("")} // Clear search value
                                            style={{ marginRight: 7, padding: 0 }}
                                        >
                                            <CloseOutlined />
                                        </Button>
                                    )
                                }
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                        </Col>
                        {filteredHistoryData?.length !== 0 && (<Col span={6} xs={24} sm={24} md={12} lg={6} xl={12} style={{ marginTop: 10 }}>
                            {displayItems &&
                                displayItems.historyLabelNote &&
                                selectedIds?.length !== 0 && (
                                    <Button
                                        type="primary"
                                        className="ant-btn-form"
                                        onClick={showModal}
                                        style={{ float: 'inline-end', backgroundColor: '#32CD32' }}
                                    >
                                        {t("AddNote")}
                                    </Button>
                                )}
                            {/* {selectedIds?.length !== 0 && */}
                            <>
                                {selectAllActive ? (
                                    <Button
                                        type="primary"
                                        className="ant-btn-form"
                                        onClick={handleSelectAll}
                                        style={{ float: 'inline-end', marginRight: '10px' }}
                                    >
                                         {t("SelectAll")}
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        danger
                                        className="ant-btn-form"
                                        onClick={handleDeselectAll}
                                        style={{ float: 'inline-end', marginRight: '10px' }}
                                    >
                                        {t("UnselectAll")}
                                    </Button>
                                )}
                            </>
                            {/* } */}
                        </Col>)}
                    </Row>










                    {/* {filteredHistoryData.length !== 0 &&(
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={selectedIds?.length == 0  ? 4 : 2}>
            {selectAllActive ? (
                    <Button
                    type="primary"
                    className="ant-btn-form"
                      onClick={handleSelectAll}
                      style={{ float:'inline-end', marginLeft:'5' }}
                    >
                      <FormattedMessage id="SelectAll" />
                    </Button>
                  ) : (
                    <Button
                    type="primary"
                    className="ant-btn-form"
                      onClick={handleDeselectAll}
                      style={{ float:'inline-end' }}
                    >
                      <FormattedMessage id="DeselectAll" />
                    </Button>
                  )}
          </Col>
        )} */}





                    {/* <Flex
            style={{
              marginBottom: "15px",
              padding: "10px",
              background: "#91caff",
            }}
            justify="space-between"
          >
            <Input
              style={{ width: "30%", borderRadius: "10px" }}
              size="small"
              placeholder="Search by name / Sch status"
              prefix={<SearchOutlined className="site-form-item-icon" />}
              suffix={
                // Conditional rendering of the cross icon
                searchValue.length > 0 && (
                  <Button
                    type="link"
                    onClick={() => setSearchValue("")} // Clear search value
                    style={{ marginRight: -7, padding: 0 }}
                  >
                    <CloseOutlined />
                  </Button>
                )
              }
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
            <span>
              {displayItems &&
              displayItems.historyLabelNote &&
              selectedIds?.length ? (
                <Button
                  type="link"
                  onClick={showModal}
                  style={{ color: "black" }}
                >
                  <FormattedMessage id="AddNote" />
                </Button>
              ) : null}

             

              {filteredHistoryData.length ? (
                <>
                  {selectAllActive ? (
                    <Button
                      type="link"
                      onClick={handleSelectAll}
                      style={{ color: "black" }}
                    >
                      <FormattedMessage id="SelectAll" />
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      onClick={handleDeselectAll}
                      style={{ color: "red" }}
                    >
                      <FormattedMessage id="DeselectAll" />
                    </Button>
                  )}
                </>
              ) : null}
            </span>
          </Flex> */}








                    <Modal
                        title={t("AddNoteToSchedules")}
                        open={isModalOpen}
                        // width={"50%"}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        closable={false}
                        footer={[
                            <Button
                                danger
                                type="primary"
                                key="cancel"
                                onClick={handleCancel}
                                className="ant-btn-modal"
                            >
                               {t("Cancel")}
                            </Button>,
                            <Button
                                key="update"
                                type="primary"
                                style={{
                                    marginTop: "2rem",
                                    padding: "0 11rem;",
                                }}
                                className="ant-btn-modal"
                                onClick={handleOk}
                                loading={loading}
                            >
                              {t("Update")}
                            </Button>,

                            note ? (
                                <Button
                                    key="copy"
                                    type="primary"
                                    className="ant-btn-modal"
                                    onClick={handleCopy}
                                    style={{
                                        marginTop: "2rem",
                                        padding: "0 11rem;",
                                    }}
                                >
                                {t("Copy")}
                                </Button>
                            ) : null,
                        ]}
                    >
                        <div>
                            <TextArea
                                showCount
                                maxLength={100}
                                onChange={handleChange}
                                value={note}
                                placeholder={t("Enter your note here")}
                                style={{ width: "100%" }}
                            />
                        </div>
                        {/* </Space> */}
                    </Modal>
                    <Modal
                        title=""
                        open={showTask}
                        // width={"50%"}
                        // onOk={handleOk}
                        onCancel={handleCancel}
                        closable={true}
                        footer={null}
                    >
                        {/* <Space direction="horizontal"> */}
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                justifyContent: "space-evenly",
                            }}
                        >
                            {taskData?.services?.map((item, index) => (
                                <Card
                                    key={index}
                                    style={{
                                        width: 300,
                                        padding: "13px",
                                        fontSize: "medium",
                                        background: "#f4f4f4",
                                        boxShadow: "#f1f1f1 0px -2px",
                                    }}
                                >
                                    {/* <span>{item.split(":")[0]}</span> */}
                                    <span>{item.split(':').slice(0, 2).join(': ')}</span>
                                </Card>
                            ))}
                            {/* </Space> */}
                        </div>
                    </Modal>
                    <InfiniteScroll
                        dataLength={filteredHistoryData.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <>
                                <Spin
                                    spinning={loading}
                                    size="large"
                                    style={{ marginLeft: "45rem" }}
                                />{" "}
                              {t("LoadingMoreData")}
                            </>
                        }
                        height={600}
                        delay={500}
                        endMessage={
                            searchValue.length === 0 && filteredHistoryData.length ? (
                                <Divider plain style={{ fontWeight: "bold" }}>
                                    {t("DataRetrievalIsComplete")}{" "}
                                </Divider>
                            ) : null
                        }
                    >
                        <Spin spinning={loading} size="large" style={{ marginTop: "3rem" }}>
                            <Row
                                gutter={[16, 16]}
                                className="mt-3"
                                style={{ margin: "0px 0px" }}
                            >
                                {filteredHistoryData.map((item, index) => (
                                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                                        <Badge.Ribbon
                                            placement="start"
                                            text={t(item.scheduleStatus)}
                                            color={getColor(item)}
                                        >
                                            <Card
                                                style={{
                                                    background: "#f0f2f5",
                                                    borderLeftWidth: "3px",
                                                    borderLeftColor: getColor(item),
                                                    fontWeight: "bold",
                                                    position: "relative",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <Meta
                                                    title={
                                                        <div style={{}}>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "4px",
                                                                    top: "0.5rem",
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedIds.includes(item._id)}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(item._id)
                                                                    }
                                                                ></Checkbox>
                                                            </div>
                                                            <div style={{ marginTop: 33, marginLeft: "5px", marginBottom: 15, }}>
                                                                <div>
                                                                    <span>
                                                                        {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {" "}
                                                                        {item.aSTime || item.sTime} - {item.aETime || item.eTime}
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            fontSize: "0.7rem",
                                                                            color: "#8546A7",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        {item?.service?.desc}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {item.clientId.fName} {item.clientId.lName}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {" "}
                                                                    {t("Duration")} :
                                                                    <span style={{ color: "#8546A7" }}>
                                                                        { } {item.duration}
                                                                    </span>
                                                                    <span style={{ float: 'inline-end' }}>
                                                                        {item?.notes?.length > 0 ? (
                                                                            <Tooltip title="Add Note">
                                                                                <Tag
                                                                                    style={{ cursor: "pointer" }}
                                                                                    color="#87d068"
                                                                                    onClick={() => showNoteModal(item)}
                                                                                >
                                                                                    {" "}
                                                                                    <DiffOutlined />
                                                                                </Tag>
                                                                            </Tooltip>
                                                                        ) : null}
                                                                    </span>
                                                                    <span style={{ float: 'inline-end' }}>
                                                                        {item?.services?.length ? (
                                                                            <Tooltip title="View Tasks">
                                                                                <Tag
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        marginLeft: "4px",
                                                                                    }}
                                                                                    color="#2db7f5"
                                                                                    onClick={() => showTaskModal(true, item)}
                                                                                >
                                                                                    <EyeFilled />
                                                                                </Tag>
                                                                            </Tooltip>
                                                                        ) :
                                                                            null}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </Card>
                                        </Badge.Ribbon>
                                    </Col>
                                ))}
                            </Row>
                        </Spin>
                        {filteredHistoryData.length === 0 && (
                            <Empty style={{ marginTop: '10rem', flexDirection: 'row', justifyContent: 'center' }} description= {t("NoHistoryFound")} />
                        )}
                    </InfiniteScroll>
                </TabPane>

                {displayItems && displayItems.historyTabEmpMissingSig && (
                    <TabPane
                        tab={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                {/* <span>
                <StopOutlined />
              </span> */}
                                <span> {t("Missing Signs")}</span>
                            </div>
                        }
                        key="2"
                    >
                        <Row
                            gutter={[8, 0]}
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: 5,
                                marginRight: 5,
                            }}
                        >
                            {userType === "e" && (
                                <Col
                                    span={6}
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={6}
                                    xl={displayItems.historyLabelNote ? 12 : 24}
                                >
                                    <Select
                                        size="large"
                                        placeholder={t("SelectClient")}
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        value={selectedClient !== "" ? selectedClient : undefined}
                                        style={{ borderColor: '#91caff' }}
                                    >
                                        <Option key="all" value="all">
                                            All
                                        </Option>
                                        {clients.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                {item.fName} {item.lName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}
                            {filteredHistoryData?.length !== 0 && userType === "c" ?
                                <Col span={6} xs={24} sm={24} md={12} lg={6} xl={12}></Col> : null}
                            <Col span={6} xs={24} sm={24} md={12} lg={6} xl={12} style={{ marginTop: 10 }}>
                                {displayItems &&
                                    displayItems.historyLabelNote &&
                                    selectedIds?.length !== 0 && (
                                        <Button
                                            type="primary"
                                            className="ant-btn-form"
                                            onClick={showModal}
                                            style={{ float: 'inline-end', backgroundColor: '#32CD32', }}
                                        >
                                            {t("AddNote" )}
                                        </Button>
                                    )}
                                {displayItems &&
                                    displayItems.historyLabelAuthenticate &&
                                    selectedIds?.length !== 0 && (
                                        <Button
                                            type="primary"
                                            className="ant-btn-form"
                                            style={{ float: 'inline-end', backgroundColor: '#00A5FF', marginRight: '10px' }}
                                            onClick={() => openSignatureModal(selectedIds)}
                                        >
                                            {t("Authenticate")}
                                        </Button>
                                    )}
                                {filteredHistoryData?.length !== 0 && <>
                                    {selectAllActive ? (
                                        <Button
                                            type="primary"
                                            className="ant-btn-form"
                                            onClick={handleSelectAll}
                                            style={{ float: 'right', marginRight: '10px' }}
                                        >
                                           {t("SelectAll")}
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            className="ant-btn-form"
                                            danger
                                            onClick={handleDeselectAll}
                                            style={{ float: 'inline-end', marginRight: '10px' }}
                                        >
                                           {t("UnselectAll")}
                                        </Button>
                                    )}
                                </>}
                                {/* } */}
                            </Col>
                        </Row>


                        <InfiniteScroll
                            dataLength={filteredHistoryData.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={
                                <>
                                    <Spin
                                        spinning={loading}
                                        size="large"
                                        style={{ marginLeft: "45rem" }}
                                    />{" "}
                                    {t("LoadingMoreData")}

                                </>
                            }
                            height={600}
                            endMessage={
                                filteredHistoryData.length ? (
                                    <Divider plain style={{ fontWeight: "bold" }}>
                                     {t("DataRetrievalIsComplete")}
                                    </Divider>
                                ) : null
                            }
                        >
                            <Spin
                                spinning={loading}
                                size="large"
                                style={{ marginTop: "3rem" }}
                            >
                                <Row
                                    gutter={[16, 16]}
                                    className="mt-3"
                                    style={{ margin: "0px 0px" }}
                                >
                                    {filteredHistoryData.map((item, index) => (
                                        <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                                            <Badge.Ribbon
                                                placement="start"
                                                text={t(item.scheduleStatus)}
                                                color={getColor(item)}
                                            >
                                                <Card
                                                    style={{
                                                        background: "#f0f2f5",
                                                        borderLeftWidth: "3px",
                                                        borderLeftColor: getColor(item),
                                                        fontWeight: "bold",
                                                        position: "relative",
                                                        boxShadow: "none",
                                                    }}
                                                >
                                                    <Meta
                                                        title={
                                                            <div style={{}}>
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "4px",
                                                                        top: "0.5rem",
                                                                    }}
                                                                >
                                                                    <Checkbox
                                                                        checked={selectedIds.includes(item._id)}
                                                                        onChange={() =>
                                                                            handleCheckboxChange(item._id)
                                                                        }
                                                                    ></Checkbox>
                                                                </div>
                                                                <div
                                                                    style={{ marginTop: 33, marginLeft: "5px", marginBottom: 10 }}
                                                                >
                                                                    <div>
                                                                        <span>
                                                                            {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            {" "}
                                                                            {item.aSTime || item.sTime} - {item.aETime || item.eTime}
                                                                        </span>
                                                                        <span
                                                                            style={{
                                                                                fontSize: "0.7rem",
                                                                                color: "#8546A7",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {item?.service?.desc}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        <span>
                                                                            {item.clientId.fName}{" "}
                                                                            {item.clientId.lName}
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        {t("Duration")}
                                                                        <span style={{ color: "#8546A7" }}>
                                                                            { } {item.duration}
                                                                        </span>
                                                                        <span style={{ float: 'inline-end' }}>
                                                                            {item?.notes?.length > 0 ? (
                                                                                <Tooltip title="Add Note">
                                                                                    <Tag
                                                                                        style={{ cursor: "pointer" }}
                                                                                        color="#87d068"
                                                                                        onClick={() => showNoteModal(item)}
                                                                                    >
                                                                                        {" "}
                                                                                        <DiffOutlined />
                                                                                    </Tag>
                                                                                </Tooltip>
                                                                            ) : null}
                                                                        </span>
                                                                        <span style={{ float: 'inline-end' }}>
                                                                            {item?.services?.length ? (
                                                                                <Tooltip title="View Tasks">
                                                                                    <Tag
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            marginLeft: "4px",
                                                                                        }}
                                                                                        color="#2db7f5"
                                                                                        onClick={() => showTaskModal(true, item)}
                                                                                    >
                                                                                        <EyeFilled />
                                                                                    </Tag>
                                                                                </Tooltip>
                                                                            ) :
                                                                                null}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            </Badge.Ribbon>
                                        </Col>
                                    ))}
                                </Row>
                            </Spin>
                            {filteredHistoryData.length === 0 && (
                                <Empty style={{ marginTop: '10rem', flexDirection: 'row', justifyContent: 'center' }} description={t("NoHistoryFound")} />
                            )}
                        </InfiniteScroll>
                    </TabPane>
                )}





                {userType === "e" &&
                    displayItems &&
                    displayItems.historyTabClientMissingSig && (
                        <TabPane
                            tab={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    {/* <span>
                <CloseCircleOutlined />
              </span> */}
                                    <span>  {t("Client Missing Signs")}
                                    </span>
                                </div>
                            }
                            key="3"
                        >



                            <Row
                                gutter={[8, 0]}
                                style={{
                                    marginTop: 10,
                                    marginBottom: 10,
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                {userType === "e" && (
                                    <Col
                                        span={6}
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={6}
                                        xl={displayItems.historyLabelNote ? 12 : 24}
                                    >
                                        <Select
                                            size="large"
                                            placeholder={t("SelectClient")}
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            value={selectedClient !== "" ? selectedClient : undefined}
                                            style={{ borderColor: '#91caff' }}
                                        >
                                            <Option key="all" value="all">
                                                All
                                            </Option>
                                            {clients.map((item, index) => (
                                                <Option key={index} value={item._id}>
                                                    {item.fName} {item.lName}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Col>
                                )}





                                {filteredHistoryData?.length !== 0 && (

                                    <Col span={6} xs={24} sm={24} md={12} lg={6} xl={12} style={{ marginTop: 10 }}>
                                        {displayItems &&
                                            displayItems.historyLabelNote &&
                                            selectedIds?.length !== 0 && (
                                                <Button
                                                    type="primary"
                                                    className="ant-btn-form"
                                                    onClick={showModal}
                                                    style={{ float: 'inline-end', backgroundColor: '#32CD32', }}
                                                >
                                                   {t("AddNote")}
                                                </Button>
                                            )}
                                        {displayItems &&
                                            displayItems.historyLabelAuthenticate &&
                                            selectedIds?.length !== 0 && (
                                                <Button
                                                    type="primary"
                                                    className="ant-btn-form"
                                                    style={{ float: 'inline-end', backgroundColor: '#00A5FF', marginRight: '10px' }}
                                                    onClick={() => openSignatureModal(selectedIds)}
                                                >
                                                    {t("Authenticate")}
                                                </Button>
                                            )}
                                        {/* {selectedIds?.length !== 0 && */}
                                        <>
                                            {selectAllActive ? (
                                                <Button
                                                    type="primary"
                                                    className="ant-btn-form"
                                                    onClick={handleSelectAll}
                                                    style={{ float: 'inline-end', marginRight: '10px' }}
                                                >
                                                      {t("SelectAll")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    className="ant-btn-form"
                                                    danger
                                                    onClick={handleDeselectAll}
                                                    style={{ float: 'inline-end', marginRight: '10px' }}
                                                >
                                                    {t("UnselectAll")}
                                                </Button>
                                            )}
                                        </>
                                        {/* } */}
                                    </Col>

                                )}
                            </Row>




                            <InfiniteScroll
                                dataLength={filteredHistoryData.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={
                                    <>
                                        <Spin
                                            spinning={loading}
                                            size="large"
                                            style={{ marginLeft: "45rem" }}
                                        />{" "}
                                        {t("LoadingMoreData")}
                                    </>
                                }
                                height={600}
                                endMessage={
                                    filteredHistoryData.length ? (
                                        <Divider plain style={{ fontWeight: "bold" }}>
                                           {t("DataRetrievalIsComplete")}
                                        </Divider>
                                    ) : null
                                }
                            >
                                <Spin
                                    spinning={loading}
                                    size="large"
                                    style={{ marginTop: "3rem" }}
                                >
                                    <Row
                                        gutter={[16, 16]}
                                        className="mt-3"
                                        style={{ margin: "0px 0px" }}
                                    >
                                        {filteredHistoryData.map((item, index) => (
                                            <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                                                <Badge.Ribbon
                                                    placement="start"
                                                    text={t(item.scheduleStatus)}
                                                    color={getColor(item)}
                                                >
                                                    <Card
                                                        style={{
                                                            background: "#f0f2f5",
                                                            borderLeftWidth: "3px",
                                                            borderLeftColor: getColor(item),
                                                            fontWeight: "bold",
                                                            position: "relative",
                                                            boxShadow: "none",
                                                        }}
                                                    >
                                                        <Meta
                                                            title={
                                                                <div style={{}}>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            right: "4px",
                                                                            top: "0.5rem",
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            checked={selectedIds.includes(item._id)}
                                                                            onChange={() =>
                                                                                handleCheckboxChange(item._id)
                                                                            }
                                                                        ></Checkbox>
                                                                    </div>
                                                                    <div
                                                                        style={{ marginTop: 33, marginLeft: "5px", marginBottom: 10 }}
                                                                    >
                                                                        <div>
                                                                            <span>
                                                                                {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                {" "}
                                                                                {item?.aSTime || item?.sTime} - {item?.aETime || item?.eTime}
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    fontSize: "0.7rem",
                                                                                    color: "#8546A7",
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                                {item?.service?.desc}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                {item?.clientId?.fName}{" "}
                                                                                {item?.clientId?.lName}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                           {t("Duration")}
                                                                            <span style={{ color: "#8546A7" }}>
                                                                                { } {item.duration}
                                                                            </span>
                                                                            <span style={{ float: 'inline-end' }}>
                                                                                {item?.notes?.length > 0 ? (
                                                                                    <Tooltip title="Add Note">
                                                                                        <Tag
                                                                                            style={{ cursor: "pointer" }}
                                                                                            color="#87d068"
                                                                                            onClick={() => showNoteModal(item)}
                                                                                        >
                                                                                            {" "}
                                                                                            <DiffOutlined />
                                                                                        </Tag>
                                                                                    </Tooltip>
                                                                                ) : null}
                                                                            </span>
                                                                            <span style={{ float: 'inline-end' }}>
                                                                                {item?.services?.length ? (
                                                                                    <Tooltip title="View Tasks">
                                                                                        <Tag
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                marginLeft: "4px",
                                                                                            }}
                                                                                            color="#2db7f5"
                                                                                            onClick={() => showTaskModal(true, item)}
                                                                                        >
                                                                                            <EyeFilled />
                                                                                        </Tag>
                                                                                    </Tooltip>
                                                                                ) :
                                                                                    null}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        />
                                                    </Card>
                                                </Badge.Ribbon>
                                            </Col>
                                        ))}
                                    </Row>
                                </Spin>
                                {filteredHistoryData.length === 0 && (
                                    <Empty style={{ marginTop: '10rem', flexDirection: 'row', justifyContent: 'center' }} description= {t("NoHistoryFound")}/>
                                )}

                            </InfiniteScroll>
                        </TabPane>
                    )}
            </Tabs>

            {showEmployeeSignature && (
                <SignatureModal
                    showSignature={showEmployeeSignature}
                    handleCancel={closeSignatureModal}
                    type="employee"
                    loading={warningLoading}
                    onDone={getEmpSignature} // Pass the callback function from the parent component
                />
            )}
            {showClientSignature && (
                <SignatureModal
                    showSignature={showClientSignature}
                    handleCancel={closeSignatureModal}
                    type="client"
                    loading={warningLoading}
                    onDone={getClientSignature} // Pass the callback function from the parent component
                />
            )}
        </>
    );
};

export default History;
